
.device-header {
    position: relative;
    margin-top: 104px;
    height: 286px;
}

.section.active {
    padding-top: 27px;
    padding-left: 44px;
    width: 385px;
    background: #FFF;
    position: absolute;
    animation: rightToLeft 0.5s forwards;
}

.section.disabled {
    padding-top: 27px;
    padding-left: 44px;
    width: 392px;
    background: #FFF;
    animation: leftToRight 0.5s forwards;
    position: absolute;
}

.count-info.active {
    margin-top: 25px;
    font-size: 24px;
    font-family: Lato;
    font-weight: 700;
    animation: rightToLeftText 0.5s forwards;
}

.count-info.disabled {
    margin-top: 25px;
    font-size: 24px;
    font-family: Lato;
    font-weight: 700;
    animation: leftToRightText 0.5s forwards;
}

.add-btn.active {
    margin-top: 62px;
    border-radius: 8px !important;
    border: 1px solid #FE6601 !important;
    background: var(--primary-orange-100, #F60) !important;
    color: var(--gray-white, #FFF) !important;
    font-size: 16px !important;
    font-family: Lato !important;
    width: 300px !important;
    height: 38px !important;
    animation: leftToRightBtn 0.5s forwards;
    transition: display 0.5s;
    display: flex;
}
.add-btn.disabled {
    margin-top: 62px;
    border-radius: 8px !important;
    border: 1px solid #FE6601 !important;
    background: var(--primary-orange-100, #F60) !important;
    color: var(--gray-white, #FFF) !important;
    font-size: 16px !important;
    font-family: Lato !important;
    width: 160px !important;
    height: 38px !important;
    animation: rightToLeftBtn 0.5s forwards;
    transition: display 0.5s;
    display: none;
}

@keyframes rightToLeftBtn {
    0% {
      opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes leftToRightBtn {
    0% {
      opacity: 0;
    }

    40% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.title-text.disabled {
    display: flex;
    align-items: center;
    column-gap: 22px;
    font-size: 32px;
    font-family: Lato;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.8px;
    animation: leftToRightText 0.5s forwards;
}

.title-text.active {
    display: flex;
    align-items: center;
    column-gap: 22px;
    font-size: 32px;
    font-family: Lato;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.8px;
    animation: rightToLeftText 0.5s forwards;
}

@keyframes rightToLeft {
    0% {
      height: 286px;
      box-shadow: 0px 17px 33px -2px rgba(28, 39, 49, 0.05);
      border-radius: 20px;
      left: 40px;
    }
    100% {
      height: 178px;
      border: 1px solid var(--neutral-gray-100, #F2F4F7);
      border-radius: 29px;
      left: 446px;
      cursor: pointer;
    }
  }

  @keyframes leftToRight {
    0% {
        height: 178px;
        border: 1px solid var(--neutral-gray-100, #F2F4F7);
        border-radius: 29px;
        left: 446px;
        cursor: pointer;
    }
    100% {
        height: 286px;
        box-shadow: 0px 17px 33px -2px rgba(28, 39, 49, 0.05);
        border-radius: 20px;
        left: 40px;
    }
  }
  
  @keyframes leftToRightText {
    0% {
        color: var(--neutral-gray-500, #637083);
    }
    100% {
        color: var(--gray-5, #E0E0E0);
    }
  }
  @keyframes rightToLeftText {
    0% {
        color: var(--gray-5, #E0E0E0);
    }
    100% {
        color: var(--neutral-gray-500, #637083);
    }
  }


  .online-text.active {
    animation: rightToLeftOnlineText 0.5s forwards;
}
.online-text.disabled {
    animation: leftToRightOnlineText 0.5s forwards;
}

  @keyframes leftToRightOnlineText {
    0% {
        color: #40BF7F;
    }
    100% {
        color: var(--gray-5, #E0E0E0);
    }
  }
  @keyframes rightToLeftOnlineText {
    0% {
        color: var(--gray-5, #E0E0E0);
    }
    100% {
        color: #40BF7F;
    }
  }

  .offline-text.active {
    animation: rightToLeftOfflineText 0.5s forwards;
}
.offline-text.disabled {
    animation: leftToRightOfflineText 0.5s forwards;
}

  @keyframes leftToRightOfflineText {
    0% {
        color: #F20D0D;
    }
    100% {
        color: var(--gray-5, #E0E0E0);
    }
  }
  @keyframes rightToLeftOfflineText {
    0% {
        color: var(--gray-5, #E0E0E0);
    }
    100% {
        color: #F20D0D;
    }
  }

  .device-query {
    position: absolute;
    bottom: 0px;
    border-bottom: 1px solid #E4E7EC;
    right: 40px;
    width: calc(100vw - 495px);
    display: flex;
    align-items: center;
    justify-content: end;
    padding-bottom: 23px;
    animation: opacityAnimation 1.5s forwards;
    opacity: 1;
  }

  .device-query .active {
    opacity: 0;
  }

  @keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  .toggle-text {
    color: var(--neutral-gray-600, #414E62);
    font-size: 16px;
    font-family: Lato;
    margin-left: 6px;
    font-weight: 400;
  }

  .select-text-1 {
    color: #637083;
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
  }

  .select-text-2 {
    color: #414E62;
    font-weight: 700;
  }

  .device-main {
    opacity: 1;
    animation: opacityAnimation 1.5s forwards;
  }

  .device-main {
    opacity: 0;
  }

  .search-location-active {
    margin-right: 18px;
  }

  .search-location {
    margin-right: 18px;
  }
