
.header-view {
    opacity: 1;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    animation: opacityAnimation 1.5s forwards;
}

.header-view .active {
    opacity: 0;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }