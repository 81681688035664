
.settings-view {
    margin-top: 100px;
    opacity: 1;
    animation: opacityAnimation 1.5s forwards;
}

.settings-view .active {
    opacity: 0;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }