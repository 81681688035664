
.live-view-header {
    display: flex;
  align-items: center;
  justify-content: space-between;
}

.live-view-footer {
    display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  color: #97a1af;
}

.live-view-title {
    font-size: 28px;
  font-weight: bold;
  font-weight: 700;
  color: #202b37;
}

.live-view-announce {
    display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  color: #414e62;
  font-weight: 500;
  font-size: 16px;
}

.live-view-filter {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E7EC;
    padding-bottom: 10px;
}

.live-view-filter-left {
    display: flex;
    align-items: center;
}

.new-wall-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}