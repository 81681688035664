.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 104px;
  }
  
  .image {
    width: 100%;
    height: auto;
    animation: shrinkImage 3s forwards; /* Adjust the animation duration as needed */
  }
  
  @keyframes shrinkImage {
    0% {
      width: 100%;
    }
    100% {
      width: 242px;
    }
  }
  
  .text {
    margin-top: 20px;
    animation: moveText 3s forwards; /* Adjust the animation duration as needed */
    color: #F60;
    text-align: center;
    /* H1/Semi Bold */
    font-size: 28px;
    font-family: Lato;
    font-weight: 600;
    line-height: 36px;
  }
  
  @keyframes moveText {
    0% {
      margin-top: 146px;
    }
    100% {
      margin-top: 30px;
    }
  }

  .input-section {
    margin-top: 180px;
    opacity: 0;
    transition: opacity 1s; /* Adjust the transition duration as needed */
  }
  
  .input-section.visible {
    opacity: 1;
  }

  .sign-in-title {
    color: var(--neutral-gray-600, #414E62);
    font-size: 24px;
    font-family: Lato;
    font-weight: 600;
    line-height: 32px;
  }

  .input-line {
    border-radius: 8px;
    border: 1px solid var(--neutral-400, #94A3B8);
    background: var(--basic-white, #FFF);
    width: 308px;
    height: 44px;
  }

  .input-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .forgot-btn {
    display: inline-flex;
    height: 30px;
    padding: 6px 12px;
    color: var(--neutral-600, #475569) !important;
    font-size: 14px !important;
    font-family: Lato !important;
    font-weight: 600 !important;
    line-height: 20px;
  }

  .sign-in-btn {
    border-radius: 8px !important;
    background: var(--primary-orange-100, #F60) !important;
    width: 308px;
    height: 44px;
    color: var(--basic-white, #FFF) !important;
    margin-top: 20px;
    font-size: 16px !important;
    font-family: Lato;
    font-weight: 600 !important;
  }

  .help-support {
    color: var(--neutral-gray-400, #97A1AF);
    font-size: 14px;
    font-family: Lato;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 1.19px;
    margin-top: 224px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    text-decoration: none !important;
  }

  .remember-me {
    color: var(--neutral-gray-600, #414E62);
    /* Paragraph S/Semi Bold */
    font-size: 14px;
    font-family: Lato;
    font-weight: 600;
    line-height: 20px;
  }