
.appliance-view {
    margin-top: 37px;
    opacity: 1;
    animation: opacityAnimation 1.5s forwards;
    padding: 0 40px;
}

.appliance-view .active {
    opacity: 0;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }